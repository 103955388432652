<template>
  <div class="template-1">
    <page-header>
      <h1 class="d-none">
        {{
          testimonyOrSuccessStory === 'testimony' ? translations.tcSubmitTestimony : translations.tcSubmitSuccessStory
        }}
      </h1>
    </page-header>
    <page-body class>
      <b-form>
        <section class="section-1 bg-block mb-4">
          <h1 class="sm mb-4">
            {{
              testimonyOrSuccessStory === 'testimony'
                ? translations.tcSubmitTestimony
                : translations.tcSubmitSuccessStory
            }}
          </h1>
          <b-row>
            <b-col sm="6" xs="12">
              <div class="font-style-2">{{ translations.tcMemberName }}</div>
              <div class="form-div mb-3">{{ this.memberName }}</div>
              <div class="font-style-2">{{ translations.tcMemberNumber }}</div>
              <div class="form-div mb-3">{{ this.memberNumber }}</div>
              <div class="font-style-2">{{ translations.tcMemberEmail }}</div>
              <div class="form-div mb-3">
                <b-form-input required v-model="memberEmail" id="txt-email" class="mb-3" maxlength="100"></b-form-input>
              </div>
              <div class="font-style-2">{{ translations.tcMemberPhone }}</div>
              <div class="form-div mb-3">
                <b-form-input required v-model="memberPhone" id="txt-phone" class="mb-3" maxlength="20"></b-form-input>
              </div>
            </b-col>
            <b-col sm="6" xs="12">
              <div class="font-style-2">{{ translations.tcCampName }}</div>
              <div class="form-div mb-3">{{ campName }}</div>
              <div class="font-style-2">{{ translations.tcCampNumber }}</div>
              <div class="form-div mb-3">{{ campNumber }}</div>
            </b-col>
          </b-row>
        </section>
        <section class="section-2 bg-block mb-4">
          <b-row>
            <b-col sm="12" xs="12">
              <div class="font-style-2">
                {{ testimonyOrSuccessStory === 'testimony' ? translations.tcTestimony : translations.tcSuccessStory }}
              </div>
              <div class="form-div mb-3">
                <b-form-textarea
                  id="testimony-textarea"
                  v-model="testimony"
                  rows="5"
                  maxlength="1000"
                ></b-form-textarea>
              </div>
              <div class="font-style-2">
                {{
                  testimonyOrSuccessStory === 'testimony'
                    ? translations.tcLocationOtherInfo
                    : translations.tcTopicOtherInfo
                }}
              </div>
              <div class="form-div mb-3">
                <b-form-input required v-model="otherInfo" id="txt-phone" class="mb-3" maxlength="20"></b-form-input>
              </div>
            </b-col>
          </b-row>
        </section>
        <section class="section-3 pt-3 pl-0 pl-sm-4 mb-5">
          <b-row>
            <b-col sm="12" xs="12">
              <div class="body">
                <div class="d-flex">
                  <b-button variant="primary" @click="handleSubmitClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
                    {{ translations.tcSubmit }}
                  </b-button>
                  <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
                    {{ translations.tcCancel }}
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </section>
      </b-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'submit-testimony',
  mixins: [translationMixin],
  data() {
    return {
      campNumber: '',
      campName: '',
      errorMessages: [],
      memberEmail: '',
      memberName: '',
      memberNumber: '',
      memberPhone: '',
      otherInfo: '',
      testimony: '',
      testimonyOrSuccessStory: 'unknown',
      translations: {},
    }
  },
  components: {
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
      saveTestimony: 'testimony/saveTestimony',
    }),
    async pageLoad() {
      try {
        let path = this.$route.path.split('/')
        this.testimonyOrSuccessStory = path[path.length - 1] === 'submit-testimony' ? 'testimony' : 'successstory'
        await Promise.all([
          this.setLoadingStatus(true),
          (this.memberName = this.demograph.formal_name),
          (this.memberNumber = this.demograph.member_number),
          (this.memberEmail = this.demograph.email),
          (this.memberPhone = this.demograph.phone),
          (this.campNumber = this.demograph.camp_number),
          (this.campName = this.demograph.camp_name),
        ])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    validate() {
      this.errorMessages = []
      if (this.testimony == '') {
        const errorMessage = this.testimonyOrSuccessStory === 'testimony'
          ? this.translations.tcTestimonyRequired
          : this.translations.tcSuccessStoryRequired
        this.errorMessages.push(errorMessage)
      }
      if (this.memberEmail == '') {
        this.errorMessages.push(this.translations.tcEmailRequired)
      }
      if (this.errorMessages.length <= 0) {
        return true
      }
      return false
    },
    async handleSubmitClick() {
      if (!this.validate()) {
        this.$swal({
          icon: 'error',
          text: this.errorMessages.join(' '),
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
        return
      }
      let data = {
        memberName: this.memberName,
        memberNumber: this.memberNumber,
        memberEmail: this.memberEmail,
        memberPhone: this.memberPhone,
        campNumber: this.campNumber,
        campName: this.campName,
        testimony: this.testimony,
        otherInfo: this.otherInfo,
        indKey: this.userLogin.indkey,
        type: this.testimonyOrSuccessStory === 'testimony' ? 'testimony' : 'successstory',
      }

      let swalTextSuccess =
        this.testimonyOrSuccessStory === 'testimony'
          ? this.translations.tcTestimonySubmitted
          : this.translations.tcSuccessStorySubmitted
      let swalTextError =
        this.testimonyOrSuccessStory === 'testimony'
          ? this.translations.tcTestimonyProblemSubmitting
          : this.translations.tcSuccessStoryProblemSubmitting
      try {
        this.setLoadingStatus(true)
        let response = await this.saveTestimony(data)
        this.setLoadingStatus(false)
        this.$swal({
          icon: response ? 'success' : 'error',
          text: response ? swalTextSuccess : swalTextError,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then(() => {
          if (response) {
            this.handleCancelClick()
          }
        })
        return
      } catch (error) {
        this.setLoadingStatus(false)
        this.$swal({
          icon: 'error',
          text: swalTextError,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
    },
    handleCancelClick() {
      this.$router.push({ name: this.testimonyOrSuccessStory === 'testimony' ? 'testimonies' : 'success-stories' })
    },
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
      prefCulture: 'user/userPreferredCulture',
      userLogin: 'user/userLogin',
    }),
  },
  async created() {
    await this.pageLoad()
    await this.getViewTranslations()
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.font-style-2 {
  //font-size: 14px !important;
  font-weight: 600;
}

.form-div {
}
</style>

